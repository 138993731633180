.header-root {  
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 10px;
  height: var(--header-height);
  width: 100%;
  background-color: var(--header-background);
  color: #fff;
  overflow: auto;
  width: 100%;
  display: flex;
  gap: 6px;
  .header-left-side-floater {
    min-width: 280px;
    height: 46px;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-left: 45px;
    img {
      height: 100%;
    }
    .location-name-user-container {
      flex: 1 1 auto;
      padding-left: 8px;
      .location-name {
        font-weight: 700;
        font-size: 18px;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
      }
      .user-name {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        font-weight: bold;
        font-size: 18px;
      }
    }
    .time-container {
      display: flex;
      align-items: center;
      font-weight: 400;
      column-gap: 10px;
      width: 180px;
      font-size: 14px;
    }
  }
  .editor-header-left-side-container {
    display: flex;
    height: 100%;
    align-items:center;
    .text {
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
    }
  }
  .header-main-container {
    flex: 1 1 auto;
    height: 46px;
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    .editor-header-container-root {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      width: 100%;
      .editor-header-right-side-container {
        display: flex;
        gap: 12px;
        .btn {
          height: 42px;
          gap: 12px;
        }
        .button {
          box-shadow: 4px 4px 3px 1px rgba(0, 0, 0, 0.5);
          background: var(--thirty-color);
          border-radius: 5px;
          padding: 0px 15px 0px 5px;
          height: 40px;
          align-items: center;
          display: flex;
          .svg-container {
            height: 100%;
            display: flex;
            align-items: center;
            svg {
              height: 50%;
              .svgText {
                fill: var(--icon-color);
              }
            }
          }
          .text {
            white-space: nowrap;
            padding-left: 10px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
