.sell-header-root {
    display: flex;
    min-width: 560px;
    height: 100%;
    gap: 12px;
    align-items: center;
    justify-content: flex-end;
    .search-container {
        padding: 0px 0.4em;
        background: var(--thirty-color);
        display: flex;
        align-items: center;
        border-radius: 5px;
        border: solid var(--border-color) 2px;
        svg{
            height: 1em;
            width: 1em;
            .svgText{
                fill: var(--icon-color);
            }
        }
        .textField-root {
            .textField-label {
                color: var(--icon-color);
                top: -9px;
                &.shrink{
                    font-size: 12px;
                    top: -6px;
                }
            }
            .textField-base {
                background: var(--thirty-color);
                border: none;
                .textField-input{
                    color:white;
                    padding: 16px 6px 4px 10px;
                }
            }
        }
    }
}