.payment-methods-container {
  
  .payment-methods-header {
    display: flex;
    align-items: center;
    gap: 12px;
    opacity: 0.7;
    svg {
      height: 18px;
    }    
  }
  .payment-methods-body {
    margin-top: 1em;
    width: 100%;
    max-width: 500px;
    margin: auto;
    .order-info {
      display: flex;
      gap: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid var(--border-color);
      padding: 12px;
      font-size: 24px;
      justify-content: center;
    }
    .sum-row {
      display: flex;
      justify-content: space-between;
      padding: 0 12px;
      //font-size: 20px;
    }
    
    .payments-needed {
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      padding: 12px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .payments-title {
      margin-top: 12px;
      margin-bottom: 6px;
      opacity: 0.7;
      font-size: 14px;
    }
    .details {
      overflow: auto;
      .methods-body-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;
        border: 1px solid var(--border-color);
        padding: 0.5em;
        border-radius: 5px;
        .value {
          gap: 6px;
          align-items: center;
          display: flex;
          .bin-icon {
              text-align: right;
              width: 20px;
              height: 20px;
          }
        }
        .bin-icon{
          height: 1em;
          width: 36px;
          svg{
            height:100%;
            .svgText{
              fill: var(--icon-color);
            }
          }
        }
      }
    }
  }
}
