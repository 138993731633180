.fiscat-admin-root {
    .display-container {
        background: #000;
        border-radius: 5px;
        padding: 1em;
        margin-bottom: 1em;
    }
    .action-btn-container {
        margin-bottom: 2em;
        .btn {
            margin-right: 1em;
            background-color: rgba(0, 0, 0, 0.2);
            color: rgba(255, 255, 255, 0.6);
            &.primary {
                color: #fff;
                background-color: #ea7c69;
            }
        }
    }
    .status-container {
        background:rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: 0.5em 1em;
        margin-bottom: 2em;
        width: 400px;
        .status-box {
            display: flex;
            justify-content: space-between;
            margin: 0.5em 0;
            .led {
                height: 16px;
                width: 16px;
                background:rgba(0, 0, 0, 0.3);
                border-radius: 100%;
                &.blue {
                    background: #24E0FF;
                    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px, #3F8CFF 0 2px 14px;
                }
                &.green {
                    background-color: #ABFF00;
                    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
                }
                &.red {
                    background: #F00;
                    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
                }
            }
        }
    }
    .day-open-modal-content {
        .input-root {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 5px;
            input {
                -webkit-tap-highlight-color: rgba(0,0,0,0);
                border: none;
                background: #fff;
                width: 170px;
                font-size: 28px;
                text-align: right;
                padding: 3px 3px 3px 12px;
                height: 46px;
            }
            .currency {
                background: #fff;
                font-size: 26px;
                color: #000;
                padding-top: 4px;
            }
        }
        .actions {
            .btn {
                background: #fff;
                color: #000;   
                &.primary {
                    background: #ea7c69;
                    color: #fff;   
                }
            }
        }
    }
    
}
.fiscat-modal {
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        text-align: center;
    }
    .message {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.fiscat-modal-bg-layer {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1; 
}
.fiscat-modal-content {
    z-index: 2;
}
.day-open-modal-content {
    .actions {
        display: flex;
        justify-content: center;
        .btn {
            margin: 24px 12px;
            &.disabled {
                opacity: 0.5;
            }
        }
    }
}