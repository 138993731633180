.left-side-root {
  .top-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding-top: 6px;
    gap: 6px;

  }
  .bottom-container {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    padding: 18px 0 26px;
    gap: 6px;
  }
  .icon-btn {
    width: 60%;
    margin: 3px;
    &.warning {
      svg {
        .svgFill {
          fill: var(--yellow);
        } 
      }
    }
    &.disabled {
      opacity: 0.2;
    }
    &.socket-connected-svg, &.ntak-connected {
      svg{
        .svgText{
          fill:green;
        }
        .svgFill{
          fill:green;
        }
        .svgStroke{
          stroke:green;
        }
      }
    }
    &.socket-not-connected-svg, &.ntak-not-connected {
      svg{
        .svgText{
          fill:red;
        }
        .svgFill{
          fill:red;
        }
        .svgStroke{
          stroke:red;
        }
      }
    }
  }
  .topBar-container-relative {
    width: 60%;
    .icon-btn {
      width: 100%;
    }
  }
}