.modal.tip-modal {
    .modal-content {
        background: transparent;
        h2 {
            text-align: center;
        }
    }
}
.tip-modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    gap: 12px;
}
.tip-modal-content {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    padding: 12px;
    .header {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 12px;
        opacity: 0.7;
        color: #fff;
        .back-icon {
            display: flex;
            align-items: center;
        }
        svg {
            height: 18px;
            .svgText {
                fill: #fff;
            }
        }
    }
    .body {
        display: flex;
        flex-direction: column;
        .total-amount {
            color: #fff;
        }
    }
    .numpad-container {
        width: 340px;
        .payment-numpad-root {
            padding: 12px;
            .current-number {
                padding: 0 60px 12px 12px;
                width: 100%;
                overflow: hidden;
                position: relative;
                .text {
                    text-align: left;
                }
                svg {
                    height: 32px;
                    position: absolute;
                    right: 12px;
                    top: 12px;
                }
            }
            .payments-without-tip {
                font-size: 16px;
                margin-bottom: 6px;
                text-align: left;
                opacity: 0.6;
            }
        }
        .btn {
            width: 100%;
            font-size: 22px;
        }
    }
    .percents-container, .amounts-container {
        display: flex;
        flex-direction: row;
        gap: 3px;
        margin-bottom: 6px;
        .btn {
            font-size: 20px;
            svg {
                height: 24px;
                width: 24px;
            }
        }
    }
}