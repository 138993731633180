.modifier-root {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--sixty-color);
  overflow: hidden;
  border-radius: 5px;
  padding: 12px;
  max-width: 800px;
  max-height: 600px;
  .header-container {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 12px;
    .back-icon {
      cursor: pointer;
      opacity: 0.7;
      display: flex;
      align-items: center;
    }
    .header-text {
      opacity: 0.7;
      cursor: pointer;
    }
    svg {
      height: 18px;
    }
    .item-name {
      flex: 1 1 auto;
      text-align: center;
      font-size: 22px;
    }
  }
  .body {

    .action-buttons {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      margin-bottom: 12px;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 12px;
      .action-button {
        cursor: pointer;
        height: 42px;
        display: flex;
        align-items: center;
        overflow: hidden;
        border-radius: 5px;
        padding: 0 6px;
        gap: 6px;
        background-color: var(--thirty-color);
        border: 1px solid var(--border-color);
        position: relative;
        .inner-bg {
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0.26;
          width: 100%;
          height: 100%;
        }
        &.selected{
          color: var(--ten-color);
          border-color: var(--ten-color);
          .inner-bg {
            background-color: var(--ten-color);
          }
          svg {
            .svgText{
              fill: var(--ten-color);
            }
            .svgStroke{
              stroke: var(--ten-color);
            }
            .svgFill{
              fill: var(--ten-color);
            }
          }
        }
      }
      svg {
        height: 100%;
        width: 24px;
        height: 24px;
        .svgText {
          fill: var(--text-color);
        }
      }
    }
    .modifier-sub-content {
      overflow: auto;
      margin-bottom: 12px;
      max-height: 320px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 12px;
    border-top: 1px solid var(--border-color);
    padding-top: 12px;
    .btn {
      gap: 6px;
      background-color: var(--green);
      color: #fff;
      svg {
        height: 24px;
      }
      &.btn-warning {
        background-color: transparent;
        //color: var(--red);
        border: 2px solid var(--red);
        position: relative;
        background-color: var(--thirty-color);
        svg {
          .svgStroke {
            stroke: var(--red);
          }
          .svgFill {
            fill: var(--red);
          }
        }
        .inner-bg {
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0.26;
          width: 100%;
          height: 100%;
          //background-color: var(--red);
          border-radius: 5px;
        }
      }
    }
  }
}
.modifier-group {
  margin-bottom: 12px;
  .modifier-group-name {
    margin-bottom: 6px;
  }
  .modifiers-container {
    display: flex;
    flex-wrap: wrap;
    min-height: 46px;
    gap: 12px;
    align-items: center;
    .radio-button-root {
      user-select: none;
      display: flex;
      flex-direction: column;
      gap: 2px;
      position: relative;
      background-color: var(--thirty-color);
      border-radius: 5px;
      border: 1px solid var(--border-color);
      padding: 6px 12px;
      align-items: center;
      cursor: pointer;
      .inner-bg {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.26;
        width: 100%;
        height: 100%;
      }
      &.selected {
        border-color: var(--ten-color);
        .inner-bg {
          background-color: var(--ten-color);
        }
      }
      .radio-button-price {
        font-size: 12px;
        color: var(--ten-color);
      }
    }
  }
}
.notes-modal, .count-modal {
  .title, h2 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 12px;
    margin-top: 0;
  }
}
.count-modal {
  .number-row-container-root {
    height: auto;
    .number-row {
      flex-wrap: wrap;
      gap: 12px;
      width: 162px;
      .number-in-circle {
        margin: 0;
      }
    }
  }
}