.numpad-root {
    background: var(--thirty-color);
    padding: 24px;
    border-radius: 5px;
    .current-number{
      font-size: 24px;
      padding: 0 40px 20px 0;
      position: relative;
      width: 276px;
      margin: auto;
      text-align: right;
      svg {
        height: 30px;
        position: absolute;
        right: 0px;
        top: -4px;
      }
    }
    .numpad-body {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      justify-content: center;
      width: 276px;
      margin: auto;
      .numpad-btn {
        display: flex;
        height: 60px;
        width: 60px;
        background: var(--sixty-color);
        border: 2px solid var(--border-color);
        border-radius: 10px;
        font-weight: 700;
        // padding-bottom: 100% ;
        position: relative;
        .number{
          position:absolute;
          display: flex;
          justify-content:center;
          align-items: center;
          height:100%;
          width:100%;
          font-size: 32px;
        }
        &.special{
          background: var(--ten-color);
        }
        svg{
          width: 24px;
          height: 24px;
          .svgText{
            fill: var(--text-color);
          }
        }
      }
    }
}