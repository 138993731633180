@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes rotateAppear {
  0% {
    opacity: 0;
    transform: rotate(0);
  }
  100% {
    opacity: 1;
    transform: rotate(-18deg);
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }
  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }
  2% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  4%,
  8%,
  12% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  6%,
  10%,
  14% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  16% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  18% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
@keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }
  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }
  2% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  4%,
  8%,
  12% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  6%,
  10%,
  14% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  16% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  18% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
@-webkit-keyframes slide-out-blurred-tr {
  0% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
    transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(1000px, -1000px) skew(-80deg, -10deg);
    transform: translate(1000px, -1000px) skew(-80deg, -10deg);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-tr {
  0% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
    transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(1000px, -1000px) skew(-80deg, -10deg);
    transform: translate(1000px, -1000px) skew(-80deg, -10deg);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
.floating-animation {
  animation: float 6s ease-in-out infinite;
}
@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes warningFlash {
  50% {
    background-color: #fdd835;
  }
}
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate2-fwd {
  0% {
    opacity: 0.3;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}
@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.border-glow-effect {
  border: 0.15em solid var(--ten-color);
  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--ten-color),
    0px 0px 0.5em 0px var(--ten-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--ten-color),
    0px 0px 0.5em 0px var(--ten-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--ten-color),
    0px 0px 0.5em 0px var(--ten-color);
}
.pulsate-fwd {
	-webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
	        animation: pulsate-fwd 1s ease-in-out infinite both;
          box-shadow: inset 0px 0px 0.5em 0px var(--ten-color),
    0px 0px 0.5em 0px var(--ten-color);
}
.pulsate2-fwd {
	-webkit-animation: pulsate2-fwd 1s ease-in-out infinite both;
	        animation: pulsate2-fwd 1s ease-in-out infinite both;
}
.jello-horizontal {
	-webkit-animation: jello-horizontal 0.9s both infinite;
	animation: jello-horizontal 0.9s both infinite;
}

.slideIn {
  animation: 0.5s slideIn forwards;
}
.slideInRight {
  animation: 0.5s slideInRight forwards;
}
.quickAppear {
  animation: 0.3s appear forwards;
}
.appear {
  animation: 1s appear forwards;
}
.slowAppear {
  animation: 2s appear forwards;
}
.rotateAppear {
  animation: 0.5s rotateAppear forwards;
}
.shake-top {
  -webkit-animation: shake-top 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) both
    infinite;
  animation: shake-top 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) both infinite;
}
.slide-out-blurred-tr {
  -webkit-animation: slide-out-blurred-tr 0.4s
    cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
  animation: slide-out-blurred-tr 0.4s cubic-bezier(0.755, 0.05, 0.855, 0.06)
    both;
}
.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-in-top {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-out-bottom {
  -webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
.flip-in-hor-bottom {
  -webkit-animation: flip-in-hor-bottom 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.warningFlash {
  animation: warningFlash 1s linear infinite;
}
@keyframes ani-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.flash {
  animation: ani-pulse 0.5s linear;
  //animation-delay: 1s;
}

@keyframes ani-appear-seventy {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0, 7;
  }
}

.animation-appear-seventy {
  animation: ani-appear-seventy 0.2s linear;
}

@keyframes ani-appear-full {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animation-appear-full {
  animation: ani-appear-full 0.2s linear;
}
