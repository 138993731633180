.device-container-root {
  padding: 10px;
  max-width: 400px;
  color: #fff;
  background-color: var(--sixty-color);
  padding: 16px 24px;
  border-radius: 5px;
  .dialog-title {
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 22px;
  }
  .dialog-content {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 15px;
    row-gap: 10px;
    .device-info-row {
      display: flex;
      font-weight: 500;
      .device-info-label {
        margin-right: 7px;
      }
      .device-info-data {
        flex-grow: 1;
        text-align: right;
      }
    }
  }
  .device-info-reg-btn-container {
    display: flex;
    justify-content: center;
    gap: 6px;
    flex-wrap: wrap;
    .btn {
      gap: 6px;
      margin: 3px;
      width: 100%;
      max-width: 200px;
      svg {
        height: 24px;
        width: 24px;
        .svgFill {
          fill: var(--btn-text-color);
        }
        .svgStroke {
          stroke: var(--btn-text-color);
        }
      }
    }
  }
}
