.ntak-provider {
    .files {
        display: flex;
        gap: 12px;
        .certificate-container {
            display: flex;
            flex-direction: column;
            gap: 6px;
            align-items: center;
            padding: 6px;
            border-radius: 5px;
            min-width: 130px;
            background-color: rgba(0, 0, 0, 0.05);
            border: 1px solid transparent;
            opacity: 0.5;
            transition: 0.5s all;
            &.valid {
                background-color: rgba(25, 162, 159, 0.05);
                border: 1px solid #19A29F;
                opacity: 1;
                .icon {
                    .svg-root {
                        svg {
                            .svgStroke {
                                stroke: var(--button-green);
                            }
                        }
                    }
                }
                .text {
                    color: var(--button-green);
                }
            }
            &.invalid {
                opacity: 1;
                .icon {
                    .svg-root {
                        svg {
                            .svgStroke {
                                stroke: var(--button-red)
                            }
                        }
                    }
                }
                .text {
                    color: var(--button-red);
                }
            }
        }
    }
    .file-upload-container {
        display: flex;
        gap: 12px;
        .upload {
            background-color: var(--button-blue);
            color: #fff;
            padding: 6px;
            border-radius: 5px;
            min-width: 130px;
            label {
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 6px;
                svg {
                    .svgFill {
                        fill: #fff;
                    }
                }
            }
        }
    }
}