.modal.payment-modal {
  z-index: 998;
  .modal-content {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    background: var(--sixty-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    
    .payment-numpad-root {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      height: 350px;
    }
    .payment-button-container-root {
      .top-row-payment-button {
        flex-wrap: wrap;
        gap: 12px;
        .payment-button {
          cursor: pointer;
          width: calc(50% - 6px);
          max-width: 180px;
          padding: 0 12px;
          gap: 6px;
          border: 2px solid var(--border-color);
          border-radius: 10px;
          svg {
            height: 24px;
            width: 24px;
          }
          &.vip {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
.payment-container-root {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: var(--sixty-color);
  color: var(--text-color);
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  padding: 66px 0 0 48px;
  // padding-right: 2em;
  width: 100%;
  height: 100%;
  .left-container {
    width: 320px;
    flex-shrink: 0;
    height: 100%;
    .slide-up-container {
      margin-top: 6px;
      border-radius: 5px;
      max-height: calc(100% - 362px);
    }
  }
  .middle-container {
    width: calc(100% - 620px);
    justify-content: center;
    height: 100%;
    .payment-methods-container {
      height: 100%;
      width: 100%;
      .payment-methods-body {
        height: 100%;
        margin-top: 0;
        padding: 6px;
        display: flex;
        flex-direction: column;
        gap: 3px;
        .details {
          margin-top: auto;
          height: 250px;
          background-color: var(--thirty-color);
          border-radius: 5px;
          padding: 0 12px 12px;
        }
      }
    }
    .payment-methods-header {
      position: absolute;
      top: 78px;
      left: 60px;
    }
  }
  .right-container {
    width: 300px;
    height: 100%;
    flex-shrink: 0;
    background-color: var(--thirty-color);
    padding: 12px;
    .order-summary-container {
      height: calc(100% - 220px);
    }
    .quick-pay-btn-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 12px;
      .btn {
        //gap: 6px;
      }
    }
  }
  .printing-alert {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    font-size: 24px;
    z-index: 999;
    text-align: center;
    padding: 24px;
  }
  .slide-up-container {
    background-color: var(--thirty-color);
    height: 163px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    border-radius: 10px;
    padding: 0 12px;
    transition: all 0.3s;
    &.open {
        height: 50vh;
        .toggle {
            transform: rotate(-180deg);
        }
    }
    .toggle {
        display: flex;
        justify-content: center;
        top: 0;
        height: 30px;
        transition: all 0.3s;
        svg {
            height: 30px;
        }
    }
    .payment-button-container-root {
        overflow: auto;
        height: calc(100% - 40px);
        padding-bottom: 12px;
        .top-row-payment-button {
            .payment-button {
                height: 50px;
            }
        }
    }
  }
  .middle-container {
    display: flex;
    gap: 12px;
    align-items: flex-start;
    .row {
      display: flex;
      justify-content: space-between;
    }
  }
}

.payment-button-container-root {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .top-row-payment-button {
    width: 100%;
    column-gap: 12px;
    display: flex;
    .payment-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      background: var(--thirty-color);
      border-radius: 5px;
      width: 100%;
      color: var(--text-color);
      font-weight: 700;
      text-align: center;
      &.selected {
        background: var(--ten-color);
        color: #fff;
        svg {
          .svgFill {
            fill: #fff;
          }
          .svgText {
            fill: #fff;
          }
          .svgStroke {
            stroke: #fff;
          }
        }
      }
      &.disabled{
        opacity: 0.35;
      }
    }
  }
  .bottom-row {
    .payment-button {
      width: 100%;
      background: var(--thirty-color);
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 2px solid var(--border-color);
      border-radius: 10px;
      &.tipp-btn {
        gap: 12px;
        max-width: 372px;
        svg {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
  .payment-button-large {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    background: var(--ten-color);
    border-radius: 5px;
    width: 100%;
    color: white;
    font-weight: 700;
  }
}

.modal.storno-modal {
  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: transparent;
    .amount {
      text-align: center;
      font-size: 18px;
      margin-bottom: 1em;
    }
    .answers {
      display: flex;
      justify-content: center;
      .btn {
        margin: 0 12px;
      }
    }
  }
}

.pay-btn {
  width: 100%;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ten-color);
  color: white;
  font-weight: bold;
  border-radius: 5px;
  &.inactive {
    opacity: 0.5;
  }
}

.open-in-modal-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  row-gap: 16px;
  padding: 24px;
  max-width: 500px;
  color: #fff;
  .text {
    text-align: center;
    font-size: 20px;
  }
  .text2 {
    font-size: 16px;
    font-weight: normal;
  }
  .button-cotainer {
    display: flex;
    justify-content:center;
    width:100%;
    .btn {
      padding: 15px;
      border-radius: 5px;
      background: var(--ten-color);
      color: #fff;
      margin: 0 12px;
    }
  }
}
.printer-error-modal {
  max-width: 500px;
  color: #fff;
  .buttons-container {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
  }
}