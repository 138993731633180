.language-selector-modal {
  .language-selector-modal-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  .language-selector-modal-container {
    margin-top: 12px;
    display: flex;
    gap: 12px;
    justify-content: center;
    max-width: 400px;
    .language-selector-item {
      display: flex;
      flex-direction: column;
      padding: 0.75em 1.25em;
      background: var(--sixty-color);
      border-radius: 5px;
      border: var(--sixty-color) 2px solid;
      max-width: 110px;
      cursor: pointer;
      &.selected {
        border: var(--ten-color) 2px solid;
      }
      .item-flag {
        display: flex;
        justify-content: center;
      }
      .item-name {
        margin-top: 1.25em;
        font-weight: 700;
      }
    }
  }
  .language-selector-modal-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    .btn {
      width: 100%;
      max-width: 250px;
    }
  }
}
