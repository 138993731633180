.device-control-panel-container {
    padding: 24px;
    height: 100%;
    overflow: auto;
    .title {
        font-size: 24px;
        font-weight: bold;
    }
    .sub-title {
        font-size: 20px;
        margin: 24px 0 6px;
    }
    .name {
        font-size: 18px;
        margin-bottom: 1em;
    }
    .function-button-container {
        display: flex;
        gap: 12px;
    }
}
.clear-cache-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    background: rgba(0, 0, 0, 0.85);
    text-align: center;
    h2 {
        color: #fff;
    }
    .btn {
        margin: 2em 1em;
        width: 100px;
        &.primary {
            background: var(--red);
            color: #fff;
        }
        &.secondary {
            background: var(--green);
            color: #fff;
        }
    }
}