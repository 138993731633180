.discount-modal-root{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: var(--sixty-color);
    padding: 0 12px 12px;
    border-radius: 5px;
    .header{
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        height: 48px;
        gap: 6px;
        .back-icon {
            cursor: pointer;
            opacity: 0.7;
            display: flex;
            align-items: center;
            svg {
                height: 18px;
                .svgText {
                    fill: #fff;
                }
            }
        }
        .header-text {
            opacity: 0.7;
            cursor: pointer;
        }
        .title {
            flex: 1 1 auto;
            text-align: center;
            font-size: 18px;
        }
    }
    .body{
        display: flex;
        gap: 6px;
        .item-list{
            display: flex;
            gap: 10px;
            flex-direction: column;
            background: var(--thirty-color);
            padding: 12px;
            border-radius: 5px;
            overflow: auto;
            width: 500px;
            height: 400px;
            .title {

            }
            .item-rows {
                flex: 1 1 auto;
                overflow: auto;
                display: flex;
                flex-direction: column;
                gap: 12px;
                .item-row { 
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    border-radius: 5px;
                    width: 100%;
                    gap: 12px;
                    background-color: rgba(0, 0, 0, 0.2);
                    padding: 6px;
                    .name {
                        border: 1px solid var(--border-color);
                        display: flex;
                        align-items: center;
                        padding: 6px 16px;
                        border-radius: 5px;
                        overflow: hidden;
                        width: 160px;
                        min-height: 34px;
                        flex-shrink: 0;
                        position: relative;
                        .inner-bg {
                            position: absolute;
                            left: 0;
                            top: 0;
                            opacity: 0.26;
                            width: 100%;
                            height: 100%;
                        }
                        &.selected {
                            color: var(--ten-color);
                            border-color: var(--ten-color);
                            .inner-bg {
                                background: var(--ten-color);
                            }
                        }
                    }
                    .discounts-container {
                        display: flex;
                        gap: 12px;
                        flex: 1 1 auto;
                        flex-direction: column;
                        .discount-container {
                            display: flex;
                            align-items: center;
                            gap: 6px;
                            height: 34px;
                            justify-content: space-between;
                            .discount-name{
                                font-size: 14px;
                            }
                            .discount-amount{
        
                            }
                            .bin-icon{
                                display: flex;
                                align-items: center;
                                svg{
                                    height: 26px;
                                    .svgText{                                
                                        fill: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .discount-list {
            height: 400px;
            overflow: hidden;
            .title {
                padding-left: 6px;
                height: 20px;
            }
            .discounts {
                display: flex;
                flex-direction: column;
                padding: 0 12px 20px;
                margin: 12px 0;
                overflow: auto;
                gap: 12px;
                height: calc(100% - 20px);
                .discount-btn {
                    border: 1px solid var(--border-color);
                    border-radius: 5px;
                    padding: 12px 16px;
                    &.not-available{
                        opacity: 0.4;
                    }
                }
            }
        }
    }
    .footer {
        display: flex;
        justify-content: space-around;
        margin-top: 12px;
        .btn {
            svg {
                height: 24px;
                .svgFill {
                    fill: var(--red);
                }
                .svgStroke {
                    stroke: var(--red);
                }
            }
            &.btn-warning {
                gap: 6px;
                border: 2px solid var(--red);
                background-color: var(--thirty-color);
                color: #fff;
            }
        }
    }
}