.order-item-container {
  position: relative;
  padding: 0.5em;
  border-radius: 5px;
  overflow: hidden;
  font-weight: bold;
  background-color: var(--thirty-color);
  font-weight: var(--orderItemFontWeight);
  font-size: var(--orderItemFontSize);
  z-index: 0;
  height: var(--orderItemHeight);
  width: calc((100% - (var(--orderItemsColumns) * 12px)) / var(--orderItemsColumns));
  filter: var(--orderItemShadow);
  cursor: pointer;
  max-width: 250px;
  min-width: 80px;
  &.back-to {
    opacity: 0.7;
  }
  &.selected {
    background-color: var(--ten-color) !important;
    color: #fff !important;
    .bottom-container {
      .order-item-price {
        color: #fff;
      }
    }
  }
  .order-item-container-return-to-previous {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    .return-to-previous {
      display: flex;
      gap: 6px;
      align-items: center;
    }
  }
  .background-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    //filter: brightness(80%);
    .image-container {
      width: 50%;
      height: 70%;
      position: absolute;
      bottom: 6px;
      left: 6px;
      border-radius: 3px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .order-item-count-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    &:active {
      background-color: var(--ten-color);
    }
    &.invisible {
      transition: all 0.2s ease;
      .order-item-count {
        display: none;
      }
    }
    &.visible {
      opacity: 0.8;
      transition: all 0.2s ease;
    }
    .order-item-count {
      font-weight: bold;
      color: #fff;
      font-size: 38px;
    }
  }
  .order-item-name {
    width: 100%;
    height: calc(100% - 2em);
    overflow: hidden;
    text-align: left;
    z-index: 1;
    position: relative;
    overflow-wrap: break-word;
  }
  .bottom-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2em;
    .icon-btn {
      width: 1.5em;
      height: 1.5em;
      svg {
        .svgFill {
          fill: #e2e2e2;
        }
      }
    }
    .order-item-price {
      width: 100%;
      text-align: right;
      padding-right: 0.5em;
      justify-content: flex-end;
      align-items: flex-end;
      font-size: 0.9em;
      color: var(--ten-color);
      overflow: hidden;
      overflow-wrap: break-word;
    }
  }
}
.sell-item-edit {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 0px;
  right: 0px;
  padding: 5px;
  z-index: 1;
  opacity: 0.15;
  height: 100%;
  width: 30%;
  background: #757575;
}
.sell-item-visibility {
  width: 100%;
  justify-content: center;
  border: 2px solid var(--border-color);
  border-radius: 5px;
}
.sell-item-count {
  position: absolute;
  display: flex;
  top: 0px;
  left: 0px;
  z-index: 2;
  opacity: 0.15;
  height: 100%;
  width: 100%;
}
