.dashboard-root {
  padding: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  align-content: flex-start;
  background: var(--sixty-color);
  .date-picker-container {
    display: flex;
    margin: 0 12px 12px 0;
  }
}
.box-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  margin-bottom: 12px;
  .box-group {
    margin-bottom: 0;
  }
  &.flex-dir-row {
    flex-direction: row;
  }
  &.flex-dir-col {
    flex-direction: column;
  }
}
.dashboard-box {
  background: var(--thirty-color);
  border-radius: 5px;
  width: 300px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 6px;
  .okeoke-data-table{
    .data-table-inner {
      padding: unset;
    }
  }
  .okeoke-loading-screen {
    border-radius: 5px;
  }
  .title {
    display: flex;
    justify-content:center;
    width: 100%;
    padding: 0.4em 0 0.4em 0.4em;
  }
  &.small {
    height: 5em;
    justify-content: flex-end;
    .label {
      width: 100%;
      padding: 0 1em 0.5em;
      overflow: hidden;
      text-align: center;
    }
    .value {
      font-weight: bold;
      width: 100%;
      padding: 0 0.5em;
      overflow: hidden;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translateY(-110%);
    }
  }
  .chart-head {
    width: 100%;
    padding: 0.4em 0 0.4em 0.4em;
  }
  .chart-container {
    width: 100%;
    height: 100%;
    padding: 0.4em;
    .text {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.dashboard-chart-container {
  height: 250px;
  width: 600px;
}

.date-selector-container {
  display: flex;
  padding-right: 1.5em;
  .date-selector {
    min-height: 42px;
    // margin-bottom: 1em;
    .textField-root {
      height: 100%;
      &.disabled {
        opacity: 1;
      }
      cursor: pointer;
      .textField-label {
        height: 100%;
        color: var(--text-color);
        font-size: 0.7em;
      }
      .textField-base {
        height: 100%;
        background-color: var(--ten-color);
        color: #fff;
        font-size: unset;
        cursor: pointer;
      }
      .textField-input{
        height: 100%;
        display: flex;
        text-align: center;
        padding: 1.8em 0.5em 0px;
        font-size: 0.6em;
      }
    }
  }
}

.dashboard-rightside-root {
  height: 100%;
  width: 100%;
  padding: 35px 0px 35px 15px;
  .dashboard-rightside-header {
    font-weight: 400;
  }
  .dashboard-rightside-grid-container {
    padding-top: 35px;
    display: grid;
    grid-template-columns: auto auto;
    font-weight: 400;
    row-gap: 5px;
    .text {
    }
    .date {
      display: flex;
      justify-content: center;
    }
  }
}
