.reload-needed-container {
  background-color: var(--red);
  padding: 6px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.8;
  color: #fff;
  margin-bottom: 12px;
}
.admin-view-root {
  width: 100%;
  height: 100%;
  background-color: var(--sixty-color);
  padding: 6px 24px;
  overflow: auto;
  .tab-selector-container {
    display: flex;
    gap: 12px;
    padding-top: 6px;
    .settings-tab {
      cursor: pointer;
      &.selected {
        border-bottom: 1px solid var(--text-color);
      }
    }
  }
  .admin-title {
    font-size: 18px;
    margin: 16px 0 6px 0;
    opacity: 0.8;
  }
}
.admin-button-group {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
.admin-button {
  cursor: pointer;
  background-color: var(--thirty-color);
  display: flex;
  padding: 14px 24px;
  align-items: center;
  font-weight: 500;
  border-radius: 5px;
  min-width: 180px;
  text-align: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  gap: 12px;
  .icon {
    height: 24px;
    width: 24px;
    svg {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &:active, &.highlighted, &.selected {
    background-color: var(--ten-color);
    color: #fff;
    svg {
      .svgFill {
        fill: #fff;
      }
      .svgStroke {
        stroke: #fff;
      }
    }
  }
  &.disabled {
    user-select: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.app-params {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  &.disabled {
      opacity: 0.3;
  }
  .option {
    cursor: pointer;
    position: relative;
    border: 2px solid var(--border-color);
    background-color: var(--thirty-color);
    padding: 6px 12px;
    border-radius: 5px;
    .inner-bg {
      opacity: 0.26;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    &.selected {
      border-color: var(--ten-color);
      .inner-bg {
        background-color: var(--ten-color);
      }
    }
  }
}
.app-params-container {
  .features-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding-left: 6px;
    width: 100%;
    max-height: 180px;
    overflow: hidden;
    overflow-y: auto;
    .feature-box {
      display: flex;
      align-items: center;
      gap: 12px;
      border: 1px solid var(--border-color);
      border-radius: 5px;
      background-color: var(--thirty-color);
      padding: 6px 12px;
      &.locked {
        opacity: 0.5;
      }
      svg {
        height: 22px;
        width: 22px;
      }
    }
  }
}