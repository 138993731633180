.device-customization {
    padding: 24px;
    display: flex;
    height: 100%;
    overflow: auto;
    gap: 12px;
    .col {
        width: 50%;
        flex-shrink: 0;
    }
    p {
        margin: 12px 0 6px;
    }
    .font-title {
        margin: 12px 0 6px;
        font-size: 24px;
    }
    .layout {
        margin: 0 0 12px 0;
        .title {
            font-size: 24px;
            margin: 12px 0 6px 0;
        }
    }
    .layout-presets {
        margin-bottom: 24px;
        flex-shrink: 0;
        .title {
            font-size: 24px;
            margin-bottom: 12px;
        }
        .presets-container {
            display: flex;
            align-items: center;
            gap: 12px;
            .preset {
                border: 1px solid var(--border-color);
                padding: 8px;
                border-radius: 5px;
                cursor: pointer;
                background: var(--thirty-color);
                position: relative;
                &.selected {
                    border-color: var(--ten-color);
                    color: #fff;
                    svg {
                        .svgFill {
                            fill: var(--ten-color);
                        }
                    }
                    .inner-bg {
                        background-color: var(--ten-color);
                        opacity: 0.26;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
                svg {
                    height: 60px;
                    width: 60px;
                }
            }
        }
    }
    .item-columns {
        display: flex;
        flex-wrap: wrap;
        .item-column-btn {
            cursor: pointer;
            background: var(--thirty-color);
            padding: 10px 20px;
            margin: 3px;
            border-radius: 5px;
            border: 1px solid var(--border-color);
            position: relative;
            &.selected {
                border-color: var(--ten-color);
                color: var(--ten-color);
                .inner-bg {
                    background-color: var(--ten-color);
                    opacity: 0.26;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }                
            }
            &.font-size {
                height: 45px;
                display: flex;
                align-items: center;
                &.font-12 {
                    font-size: 12px;
                }
                &.font-14 {
                    font-size: 14px;
                }
                &.font-16 {
                    font-size: 16px;
                }
                &.font-18 {
                    font-size: 18px;
                }
                &.font-20 {
                    font-size: 20px;
                }
            }
            &.font-weight {
                height: 45px;
                display: flex;
                align-items: center;
                &.font-200 {
                    font-weight: 200;
                }
                &.font-400 {
                    font-weight: 400;
                }
                &.font-600 {
                    font-weight: 600;
                }
                &.font-800 {
                    font-weight: 800;
                }
            }
            &.nunito {
                font-family: 'Nunito', sans-serif;
            }
            &.barlow {
                font-family: 'Barlow', sans-serif;
            }
            &.poppins {
                font-family: 'Poppins', sans-serif;
            }
            &.montserrat {
                font-family: 'Montserrat', sans-serif;
            }
        }
    }
    .button-colors {
        margin-bottom: 1em;
        .title {
            font-size: 24px;
            margin-bottom: 12px;
        }
        .color-pickers {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            .color-picker {
                cursor: pointer;
                border-radius: 5px;
                padding: 6px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 3px solid var(--border-color);
                font-size: 14px;
                //border: 3px solid rgba(0, 0, 0, 0.3);
                
                &.selected {
                    border: 3px solid var(--text-color);
                }
            }
        }
    }
    .color-mode {
        margin-bottom: 12px;
        .title {
            font-size: 24px;
            margin-bottom: 12px;
        }
        .mode-pickers  {
            display: flex;
            gap: 16px;
            .mode-picker {
                cursor: pointer;
                border-radius: 5px;
                border: 3px solid #cecece;
                height: 80px;
                width: 140px;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 6px;
                font-size: 14px;
                .inner-bg {
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                &.selected {
                    border-color: var(--ten-color);
                    color: var(--ten-color);
                    font-weight: bold;
                    svg {
                        .svgFill {
                            fill: var(--ten-color);
                        }
                        .svgStroke {
                            stroke: var(--ten-color);
                        }
                    }
                    .inner-bg {
                        background-color: var(--ten-color);
                        opacity: 0.26;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    .others {
        .title {
            font-size: 24px;
            margin-bottom: 12px;
        }
    }
}