.sell-outer-container {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.sell-root {
  height: 100%;
  min-width: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: var(--sellRootFlexDirection);
  position: relative;
  flex: 1 1 auto;
}
.sell-not-allowed {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 20;
  flex-direction: column;
  gap: 12px;
  color: #fff;
  .text {
    max-width: 500px;
  }
  input {
    background-color: #fff;
  }
}
.last-item {
  padding: 6px;
  background: var(--header-background);
  color: #fff;
  position: absolute;
  overflow: hidden;
  bottom: 40px;
  left: 0;
  width: 100%;
  height: 46px;
}
.swiper-controller {
  padding: 6px;
  background: var(--header-background);
  color: #fff;
  text-align: center;
  position: fixed;
  bottom: 0px;
  left: 42px;
  width: calc(100% - 42px);
  height: 40px;
  z-index: 40;
  display: flex;
  justify-content: space-around;
  svg {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
  }
  .control {
    display: flex;
    flex-direction: column;
    .dot {
      margin-top: -6px;
      color: var(--ten-color);
      visibility: hidden;
    }
    svg {
      .svgFill {
        fill: #fff;
      }
      .svgStroke {
        stroke: #fff;
      }
      .svgText {
        fill: #fff;
      }
    }
    &.selected {
      .dot {
        visibility: visible;
      }
      svg {
        .svgFill {
          fill: var(--ten-color);
        }
        .svgStroke {
          stroke: var(--ten-color)
        }
        .svgText {
          fill: var(--ten-color);
        }
      }
    }
  }
}
.fiscal-printer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 19;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 36px;
  color: #fff;
  .title {
    font-size: 22px;
    font-weight: bold;
  }
  .buttons-container {
    display: flex;
    gap: 12px;
  }
}
.categories-container {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  //justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px 12px 0;
  background: var(--sixty-color);
  background-color: rgba(0, 0, 0, 0.05);
  gap: 12px;
  &.vertical {
    width: var(--verticalCategWidth);
    height: calc(100% - 45px);
    padding: 12px;
    overflow: auto;
    flex-shrink: 0;
    &.pagination-off {
      height: 100%;
    }
  }
  .message {
    width: 100%;
    margin-bottom: 12px;
  }
  .back-btn {
    height: 41px;
    position: absolute;
    top: 12px;
    left: 12px;
    width: 185px;
  }
  .category-container {
    font-size: 20px;
    cursor: pointer;
    //display: flex;
    padding: 6px 24px 8px;
    border: 2px solid var(--border-color);
    border-radius: 5px;
    font-weight: 500;
    filter: var(--orderItemShadow);
    background-color: var(--thirty-color);
    overflow-wrap: break-word;
    overflow: hidden;
    flex-shrink: 0;
    //height: 3em;
    &.full-width {
      &:first-of-type {
        margin-top: 0;
      }
      width: 100%;
      padding: 6px 12px;
    }
  }
  .category-container.selected {
    color: var(--ten-color);
    border: 2px solid var(--ten-color);
    font-style: normal;
    //box-shadow: 0px 0px 4px 0px var(--ten-color);
    position: relative;
    .inner-bg {
      background-color: var(--ten-color);
      opacity: 0.26;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .category-container.disabled {
    opacity: 0.4;
  }
}
.order-items-container {
  background: var(--sixty-color);
  padding: 12px 0 80vh 12px;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 12px;
  flex: 1 1 auto;
  position: relative;
  &.vertical {
    padding-top: 12px;
    height: calc(100% - 45px);
    &.pagination-off {
      height: calc(100% - 12px);
    }
  }
  &.disabled{
    user-select: none;
    opacity: 0.5;
  }
  .does-not-fit-message {
    .message {
      padding-left: 6px;
    }
    .btn {
      margin-top: 12px;
    }
  }
}
.summary-root {
  flex: 0 0 300px;
  background-color: var(--thirty-color);
  padding: 12px;
  height: 100%;
  position: relative;
  overflow: hidden;
  .order-summary-payment-container {
    height: 91px;
    border-radius: 10px;
    padding: 3px 10px 10px 10px;
    background-color: var(--sixty-color);
    transition: all 0.3s ease-in-out;
    .btn-container {
      display: flex;
    }
    &.submit-on {
      .btn {
        width: calc(50% - 10px);
        margin: 0 5px;
        font-size: 14px;
        flex-direction: column;
        display: flex;
      }
    }
    .details {
      overflow: hidden;
      height: 145px;
      transition: all 0.3s ease-in-out;
      margin-bottom: 6px;
    }
    .btn {
      width: 100%;
      height: 48px;
      min-height: 48px;
      z-index: 20;
    }
    &.open {
      height: 236px;
      .toggler {
        svg {
          transition: all 0.3s ease-in-out;
          transform: rotate(-180deg);
        }
      }
    }
    &.closed {
      .details {
        height: 0;
      }
    }
    .toggler {
      display: flex;
      justify-content: center;
      cursor: pointer;
      height: 24px;
      min-height: 24px;
      svg {
        height: 24px;
        transition: all 0.3s ease-in-out;
      }
    }
    .order-payment-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 4px;
      font-size: 14px;
      border-top: 1px solid var(--border-color);
      margin-bottom: 3px;
      &:first-of-type {
        border-top: none;
      }
    }
  }
}