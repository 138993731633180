/////////Order Viewer
.main-container {
  .order-view-content {
    background: var(--sixty-color);
    width: 100%;
    height: 100%;
    display: flex;
    .table-list {
      overflow: hidden;
      transition: all 0.3s;
      .table-list-container {
        height: 100%;
        padding: 12px 0 12px 6px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .table-card {
          border-radius: 5px;
          padding: 6px;
          border: 2px solid var(--border-color);
          background-color: var(--thirty-color);
          cursor: pointer;
          position: relative;
          margin-right: 5px;
          .table-badge {
            position: absolute;
            top: -6px;
            right: -6px;
            background-color: var(--ten-color);
            color: #fff;
            border-radius: 100%;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
          }
          &.selected {
            border-color: var(--ten-color);
          }
          .table-payments-needed {
            text-align: right;
            margin-top: 4px;
            font-size: 12px;
          }
        }
      }
      &.open {
        width: 120px;
        .table-list-container {
          transition: opacity 2s;
          opacity: 1;
        }
      }
      &.closed {
        width: 0;
        .table-list-container {
          opacity: 0;
        }
      }
    }
    .order-view-right-side {
      display: flex;
      flex-direction: column;
      overflow: auto;
      position: relative;
      flex: 1 1 auto;
    }
    &.padding-left {
      padding-left: 42px;
    }
    .offline-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 4px;
      background-color: var(--red);
      opacity: 0.8;
      text-align: center;
    }
    .order-view-top-bar {
      display: flex;
      width: 100%;
      padding: 12px 12px 0 12px;
      overflow: auto;
      flex-shrink: 0;
      gap: 12px;
      .order-view-top-bar-element {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 96px;
        height: 56px;
        background: var(--thirty-color);
        padding: 12px;
        border-radius: 5px;
        border: 1px solid var(--border-color);
        cursor: pointer;
        overflow: hidden;
        .order-state {
          font-size: 12px;
          text-align: center;
        }
        &.ready {
          color: #fff;
          background: var(--green);
        }
      }
    }
    .order-view-right-side {
      .order-view-card-container {
        gap: 16px;
        width: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex: 1 1 auto;
        padding: 12px;
        align-content: flex-start;
      }
    }
  }
  .order-card-root {
    user-select: none;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 280px;
    min-height: 130px;
    justify-content: left;
    border-radius: 8px;
    border: 3px solid var(--border-color);
    overflow: hidden;
    transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    .loading-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 50;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.highlighted {
      border: 3px solid var(--ten-color);
      box-shadow: 0px 0px 4px 0px var(--ten-color);
    }
    &.ready {
      border: 3px solid var(--green);
      box-shadow: 0px 0px 4px 0px var(--green);
    }
    &.warning {
      border: 3px solid var(--yellow);
      box-shadow: 0px 0px 4px 0px var(--yellow);
    }
    .order-card-header {
      padding: 6px 12px;    
      position: relative;
      background: var(--thirty-color);
      color: var(--text-color);
      border-bottom: 1px solid var(--border-color);
      cursor: pointer;
      .row {
        margin: 0 0 4px 0;
        display: flex;
        gap: 4px;
        .icon-container {
          svg {
            height: 24px;
            width: 24px;
            .svgStroke {
              stroke: #AAAAAA; 
            }
          }
        }
        &.space-between {
          justify-content: space-between;
        }
        &.center {
          justify-content: center;
        }
        &.space-around {
          justify-content: space-around;
          align-items: flex-start;
        }
        .icon-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .text {
            font-size: 12px;
            margin-top: 3px;
            color: #AAAAAA;
            max-width: 60px;
            text-align: center;
          }
        }
      }
      .order-card-order-takeAway {
        font-weight: bold;
        font-size: 17px;
      }
      .order-card-order-number {
        font-weight: bold;
      }
      .order-state {
        text-align: center;
        font-size: 14px;
        &.font-bold {
          font-weight: bold;
        }
      }
    }
    .order-card-body {
      flex: 1 1 auto;
      background: var(--thirty-color);
      overflow: auto;
      padding-top: 6px;
      .text {
        font-size: 12px;
        padding-left: 6px;
      }
      .order-notes {
        padding: 0 12px;
      }
      .items-container {
        padding: 12px 0;
        .order-card-item-root {
          margin-bottom: 12px;
          &:last-of-type {
            margin-bottom: 0;
          }
          .row {
            display: flex;
            gap: 12px;
          }
          .item-count {
            flex: 0 0 40px;
            text-align: right;
            padding-left: 12px;
          }
          .item-size, .item-notes {
            padding: 0 12px 0 52px;
          }
          .modifier-container {
            display: flex;
            padding-left: 52px;
            font-size: 14px;
            font-style: italic;
            gap: 6px;
            .modifier-count {
              flex: 0 0 20px;
              text-align: right;
            }
          }
        }
      }
    }
    .order-card-footer {
      background: var(--thirty-color);
      .order-card-insert-time {
        text-align: right;
        padding: 6px;
        font-size: 12px;
      }
    }
  }

  .card-order-controller {
    font-weight: bold;
    position: absolute;
    z-index: 2;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    justify-content: center;
    padding: 12px 12px 52px;
    width: 100%;
    height: 100%;
    .btn-estimate {
      display: flex;
      flex-direction: column;
      padding: 3px;
    }
    .bottom-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      gap: 6px;
      flex-wrap: wrap;
      padding: 6px;
      justify-content: center;
      height: 52px;
      .btn {
        padding: 8px;
        height: 40px;
      }
    }
  }

  .card-order-controller-background {
    background-color: var(--thirty-color);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0.8;
    border-radius: 8px;
    z-index: 1;
  }

  .open-orders-header-root {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    text-transform: uppercase;
    gap: 12px;
    padding: 0px 12px 0px;
    font-weight: bold;
    .btn {
      svg {
        .svgFill {
          fill: #000;
        }
      }
      &.btn-highlighted {
        svg {
          .svgFill {
            fill: #fff;
          }
        }
      }
    }
    .open-orders-header-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: var(--thirty-color);
      font-weight: 700;
      white-space: nowrap;
      border: 1px solid var(--border-color);
      padding: 0 12px;
      cursor: pointer;
      &.selected {
        background: var(--ten-color);
      }
    }
  }

  .order-view-table-header {
    display: flex;
    padding: 12px 12px 0;
    gap: 12px;
    .start-new-order {
      margin-left: auto;
    }
  }
}