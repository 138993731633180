// -----------------------------------------------------------------------------
// This file contains very basic styles. 
// -----------------------------------------------------------------------------
#root {
  --icon-color: #abbbc2;
  --icon-selected-color: var(--ten-color);
  --btn-bg-color: #e8e8e8;
  --btn-text-color: #252836;
  --btn2-bg-color: var(--ten-color);
  --btn2-text-color: #fff;
  --header-height: 66px;
  --right-side-width: 300px;
  --left-side-width: 42px;
  --green: #43A047;
  --yellow: #F1C40F;
  --red: #C62828;
  --toastify-color-light:#252836;
  --toastify-text-color-light: #fff;
}
/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}
/**
 * Clear inner floats
 */
 .clearfix::after {
  clear: both;
  content: '';
  display: table;
}
.d-none {
  display: none !important;
}
table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}
.hidden {
  opacity: 0;
}
textarea:focus, input:focus{
  outline: none;
}
body {
  background-color: var(--sixty-color);
  color: var(--text-color);
  user-select: none;
}
.nobreak {
  white-space: nowrap;
}
.svgText2 {
  fill: var(--ten-color);
}
input[type=date], input[type=time], input[type=datetime-local] {
  border: 1px solid var(--border-color);
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  color: #000;
  background-color: #fff;
}
.pos-version {
  position: fixed;
  bottom: 3px;
  left: 0px;
  font-size: 10px;
  z-index: 99999;
  opacity: 0.5;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3px;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.menu-toggler {
  position: fixed;
  top: 16px;
  left: 7px;
  font-size: 10px;
  z-index: 998;
  color: #fff;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  transition: top 0.3s ease-in-out;
  .error-badge {
    background-color: rgba(255, 0, 0, 0.7);
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    top: -3px;
    right: -3px;
  }
  svg {
    width: 30px;
    height: 30px;
    .svgFill {
      fill: #fff;
    }
    .svgStroke {
      stroke: #fff;
    }
  }
}
.update-container {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 14px;
  z-index: 99999;
  width: 100%;
  opacity: 0.7;
  text-align: center;
  background-color: var(--red);
  color: #fff;
  padding: 2px;
}
.react-code-input {
  display: flex !important;
  justify-content: center;
  align-items: center;
  input {
    text-align: center !important;
    margin: 0 10px !important;
    height: 60px !important;
    width: 52px !important;
    font-size: 32px !important;
    padding-left: 12px !important;
  }
}
input[type=number] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}